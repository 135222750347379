import React from 'react'
import Layout from '../components/Layout'

export default ()=>{
    return(
    <>
        <Layout>
            <h1>404 - Page Not Found</h1>
        </Layout>
    </>
    )
}